import React from "react"
import { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Panel from "../components/Panel"
import PanelContent from "../components/PanelContent"
import FluidImage from "../components/FluidImage"

export const ContactTemplate = ({
  image,
  contain = false,
  panel: {
    title: panelTitle,
    text,
    text_secondary,
    videos,
    links,
    icons,
    exposition
  }
}) => {
  const [toggle, setToggle] = useState(false)

  const handleClick = () => setToggle(!toggle)

  return (
    <>
      <div className="content__panel-clicker" onClick={handleClick}>
        <FluidImage image={image} alt="" contain={contain} />
      </div>
      <Panel toggle={toggle}>
        <PanelContent
          title={panelTitle}
          text={text}
          text_secondary={text_secondary}
          videos={videos}
          links={links}
          icons={icons}
          exposition={exposition}
        />
      </Panel>
    </>
  )
}

const Contact = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout className="contact">
      <ContactTemplate
        image={frontmatter.image}
        contain={frontmatter.contain}
        panel={frontmatter.panel}
      />
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query ContactTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        contain
        panel {
          title
          text
          text_secondary
          videos {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            link
            title
            subtitle
            duration
          }
          links {
            label
            label_secondary
            link
          }
          icons {
            icon
            link
          }
          exposition {
            exposition_hide
            exposition_date
            exposition_text
          }
        }
      }
    }
  }
`
